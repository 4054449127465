<template>
  <div class="mx-2">
    <b-row align-h="between">
      <div class="d-flex">
        <feather-icon icon="ImageIcon" size="19" class="ml-1" />
        <h4 class="ml-1">{{ $t('propertiesModel.gallery.title') }}</h4>
      </div>
      <b-button variant="primary" @click="isUploadModalOpen = true">
        {{ $t('buttons.uploadImage') }}
        <feather-icon icon="UploadIcon" class="ml-1"
      /></b-button>
    </b-row>

    <div v-if="!propertyData.images || propertyData.images.length <= 0">
      <b-row align-h="center">
        <p class="mt-4">
          {{ $t('propertiesModel.gallery.warning') }}
        </p>
      </b-row>
    </div>

    <div v-else>
      <b-row align-h="start">
        <b-col cols="12" lg="8" md="8">
        <p class="mt-1">
          {{ $t('propertiesModel.gallery.instructions') }}
        </p>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-4">
      <draggable v-model="propertyData.images" class="grid-container">
        <div class="mx-1 mt-1" v-for="(img, i) in propertyData.images" :key="i">
          <b-badge class="badge-position" variant="primary">{{ i }}</b-badge>
          <b-row align-h="end" class="mr-0">
            <b-button
              variant="danger"
              size="sm"
              class="delete-img-btn"
              @click=";(deleteModal = true), (selectedItem = img)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-row>
          <b-card
            style="
              height: 250px;
              width:300px
              margin: 0;
              border-style: dotted;
              border-width: 1px;
            "
          >
            <img
              v-if="img.path"
              :src="createUrl(img)"
              width="100%"
              height="200px"
            />
            <img v-else :src="loadFromFile(img)" width="100%" />
            <!-- <div class="upload-img-container">
              <p class="text-center mt-1" style="color: black">
                Uploaded on: {{ parseUploadedDate(img.created_at) }}
              </p>
            </div> -->
          </b-card>
        </div>
      </draggable>
    </b-row>

    <!-- Upload Modal -->
    <b-modal
      v-model="isUploadModalOpen"
      hide-footer
      :title="$t('propertiesModel.gallery.modal.title')"
      @close="closeModal"
    >
      <p>{{ $t('propertiesModel.gallery.modal.paragraph') }} (.png|.jpg|.jpeg)</p>
      <b-form-file
        v-model="uploadedFiles"
        :state="Boolean(uploadedFiles)"
        :placeholder="$t('propertiesModel.gallery.modal.placeholder')"
        :drop-placeholder="$t('propertiesModel.gallery.modal.dropPlaceholder')"
        :browse-text="$t('buttons.browse')"
        accept="image/png, image/jpeg, image/jpg"
        multiple
      />
      <b-button class="mt-1 mr-1" variant="primary" @click="loadFiles()">{{
        $t('buttons.upload')
      }}</b-button>
    </b-modal>

    <!-- Confirm img delete -->
    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('propertiesModel.gallery.alert.delete.message')"
      :title="$t('propertiesModel.gallery.alert.delete.title')"
      @action="deleteFile(selectedItem), (selectedItem = {})"
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BRow,
  BButton,
  BModal,
  BCol,
  BFormFile,
  BCard,
  BBadge,
} from 'bootstrap-vue'
import PropertyModel from '@/models/property.model'
import PropertyImagesController from '@/controllers/property-images.controller'
import AlertDialog from '@/components/AlertDialog.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    BRow,
    BButton,
    BModal,
    BCol,
    BFormFile,
    BCard,
    draggable,
    BBadge,

    AlertDialog,
  },
  props: {
    data: {
      type: PropertyModel,
    },
    isNew: {
      type: Boolean,
    },
  },
  data() {
    return {
      isUploadModalOpen: false,
      uploadedFiles: null,
      deleteModal: false,
      selectedItem: {},
    }
  },
  computed: {
    propertyData: {
      get() {
        return this.data
      },
      set(value) {
        this.$emit('updateData', value)
      },
    },
  },
  methods: {
    closeModal() {
      this.isUploadModalOpen = false
      this.uploadedFiles = null
    },
    loadFromFile(imgFile) {
      return URL.createObjectURL(imgFile)
    },
    async loadFiles() {
      if (!this.propertyData.images) this.propertyData.images = []
      this.propertyData.images = this.propertyData.images.concat(
        this.uploadedFiles,
      )
      this.closeModal()
    },
    createUrl(imgObj) {
      return `${process.env.VUE_APP_API_API_PUBLIC_FILES_URL}/${imgObj.path}`
    },
    parseUploadedDate(dateString) {
      if (dateString) return new Date(dateString).toDateString()
      else return new Date().toDateString()
    },
    async deleteFile(img) {
      if (img.id) {
        const propertyImgController = new PropertyImagesController()
        await propertyImgController.removeImageFromProperty(
          this.propertyData.id,
          img.id,
        )
        this.$emit('removeImgObjFromData', img)
      } else {
        this.$emit('removeImgFileFromData', img)
      }

      this.deleteModal = false
    },
  },
}
</script>

<style scoped>
.upload-img-container {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(220, 220, 220, 0.6);
  width: 100%;
}

.delete-img-btn {
  position: absolute;
  z-index: 10;
}

.grid-container {
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: 80vw;
  grid-auto-rows: 0.2fr;
}

.badge-position {
  position: absolute;
  z-index: 10;
}
</style>
