<template>
  <div class="mx-2">
    <!-- Header: Property Details -->
    <div class="d-flex">
      <feather-icon icon="HomeIcon" size="19" />
      <h4 class="ml-1">{{ $t('propertiesModel.propertyDetails.title') }}</h4>
    </div>

    <b-row align-v="center" align-h="start" class="mt-1">
      <!-- Field: Bedrooms -->
      <b-col cols="12" lg="2" md="2" sm="4" xs="6">
        <validation-provider
          #default="validationContext"
          name="Bedrooms"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.bedrooms')"
            label-for="qtyBathrooms"
          >
            <b-form-input
              v-model="propertyData.qtyBedrooms"
              class="form-control"
              placeholder="2"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Bathrooms -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider
          #default="validationContext"
          name="Bathrooms"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.bathrooms')"
            label-for="qtyBathrooms"
          >
            <b-form-input
              v-model="propertyData.qtyBathrooms"
              class="form-control"
              placeholder="1"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Restrooms -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Restrooms">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.restrooms')"
            label-for="qtyRestrooms"
          >
            <b-form-input
              v-model="propertyData.qtyRestrooms"
              class="form-control"
              placeholder="0"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Total Floor -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Total Floors">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.totalFloor')"
            label-for="Total Floors"
          >
            <b-form-input
              v-model="propertyData.totalFloors"
              class="form-control"
              placeholder="4"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Orientation -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Orientation">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.orientation')"
            label-for="Orientation"
          >
            <v-select
              v-model="propertyData.orientation"
              :options="
                ORIENTATION.map((orientation) => ({
                  label: $t('enums.orientation.' + orientation),
                  value: orientation,
                }))
              "
              :clearable="false"
              :state="getValidationState(validationContext)"
              :placeholder="$t('propertiesModel.propertyDetails.orientation')"
              item-value="value"
              item-text="label"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Build Surface -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Build Surface">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.buildSurface')"
            label-for="Build Surface"
          >
            <b-form-input
              v-model="propertyData.buildSurface"
              class="form-control"
              placeholder="1000"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Useful Surface -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Useful Surface">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.usefulSurface')"
            label-for="Useful Surface"
          >
            <b-form-input
              v-model="propertyData.usefulSurface"
              class="form-control"
              placeholder="500"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Plot Meters -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Plot Meters">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.plotMeters')"
            label-for="Plot Meters"
          >
            <b-form-input
              v-model="propertyData.plotMeters"
              class="form-control"
              placeholder="100"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Conservation Meters -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider
          #default="validationContext"
          name="Conservation Meters"
        >
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.conservationMeters')"
            label-for="Conservation Meters"
          >
            <b-form-input
              v-model="propertyData.conservationMeters"
              class="form-control"
              :placeholder="
                $t('propertiesModel.propertyDetails.conservationMeters')
              "
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Year Built -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Year Built">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.yearBuilt')"
            label-for="Year Built"
          >
            <b-form-input
              v-model="propertyData.yearBuilt"
              class="form-control"
              placeholder="2000"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: IBI -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="IBI">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.ibi')"
            label-for="IBI"
          >
            <b-form-input
              v-model="propertyData.ibi"
              class="form-control"
              placeholder="100"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Garbage Tax -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Garbage Tax">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.garbageTax')"
            label-for="Garbage Tax"
          >
            <b-form-input
              v-model="propertyData.garbageTax"
              class="form-control"
              placeholder="0"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="start" align-h="start">
      <!-- Field: Community Fee -->
      <b-col lg="2" md="2" sm="4" xs="6">
        <validation-provider #default="validationContext" name="Community Fee">
          <b-form-group
            :label="$t('propertiesModel.propertyDetails.communityFee')"
            label-for="Community Fee"
          >
            <b-form-input
              v-model="propertyData.communityFee"
              class="form-control"
              placeholder="10"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="start" align-h="start" class="mt-1">
      <b-col cols="12" lg="3" md="3" sm="4" xs="6">
        <!-- Checkbox: Furnished -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isFurnished" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.isFurnished')
          }}</span>
        </div>

        <!-- Checkbox: Has Community Parking -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasCommunityParking" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasCommunityParking')
          }}</span>
        </div>

        <!-- Checkbox: Has Wardrobes -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasWardrobes" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasWardrobes')
          }}</span>
        </div>

        <!-- Checkbox: Has Chimney -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasChimney" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasChimney')
          }}</span>
        </div>

        <!-- Checkbox: Has Video Intercom -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasVideoIntercom" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasVideoIntercom')
          }}</span>
        </div>

        <!-- Checkbox: Was Lived -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.wasLived" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.wasLived')
          }}</span>
        </div>
      </b-col>
      <b-col cols="12" lg="3" md="3" sm="4" xs="6">
        <!-- Checkbox: Has Elevator -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasElevator" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasElevator')
          }}</span>
        </div>

        <!-- Checkbox: Has Balcony -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasBalcony" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasBalcony')
          }}</span>
        </div>

        <!-- Checkbox: Has Gallery -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasGallery" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasGallery')
          }}</span>
        </div>

        <!-- Checkbox: Has Storage Room -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasStorageRoom" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasStorageRoom')
          }}</span>
        </div>

        <!-- Checkbox: Has Pool -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasPool" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasPool')
          }}</span>
        </div>

        <!-- Checkbox: Has Air Conditioner -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasAirConditioner" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasAirConditioner')
          }}</span>
        </div>
      </b-col>
      <b-col cols="12" lg="3" md="3" sm="4" xs="6">
        <!-- Checkbox: Has Heating -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasHeating" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasHeating')
          }}</span>
        </div>

        <!-- Checkbox: Has Reception -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasReception" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasReception')
          }}</span>
        </div>

        <!-- Checkbox: Has Sports Zone -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasSportsZone" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasSportsZone')
          }}</span>
        </div>

        <!-- Checkbox: Has Playground -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasPlayground" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasPlayground')
          }}</span>
        </div>

        <!-- Checkbox: Has Garden -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasGarden" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasGarden')
          }}</span>
        </div>

        <!-- Checkbox: Has Terrace -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox
            v-model="propertyData.hasTerrace"
            @change="$emit('hasTerraceChanged', $event)"
          />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.terrace')
          }}</span>
        </div>
      </b-col>
      <b-col cols="12" lg="3" md="3" sm="4" xs="6">
        <!-- Checkbox: Has Alarm -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasAlarm" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.hasAlarm')
          }}</span>
        </div>
        <!-- Checkbox: Is Brand New -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isBrandNew" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.isBrandNew')
          }}</span>
        </div>

        <!-- Checkbox: Is Renovated -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isRenovated" />
          <span class="font-weight-bold">{{
            $t('propertiesModel.propertyDetails.booleans.isRenovated')
          }}</span>
        </div>
      </b-col>
    </b-row>

    <b-row align-h="between" class="mt-1">
      <b-col cols="12" lg="4" md="4" sm="6">
        <!-- Field: Kitchen Type -->
        <div class="media d-flex align-items-center">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.kitchenType')
          }}</span>
          <validation-provider #default="validationContext" name="Kitchen Type">
            <b-form-group label-for="Kitchen Type">
              <v-select
                v-model="propertyData.kitchenType"
                :options="
                  KITCHEN_TYPES.map((kitchenType) => ({
                    label: $t('enums.kitchenType.' + kitchenType),
                    value: kitchenType,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t('propertiesModel.propertyDetails.enumerators.kitchenType')
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Kitchen Equipped -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.kitchenEquipped')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Kitchen Equipped"
          >
            <b-form-group label-for="Kitchen Equipped">
              <v-select
                v-model="propertyData.equippedKitchen"
                :options="
                  KITCHEN_EQUIPPED.map((equippedKitchen) => ({
                    label: $t('enums.equippedKitchen.' + equippedKitchen),
                    value: equippedKitchen,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t(
                    'propertiesModel.propertyDetails.enumerators.kitchenEquipped',
                  )
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Interior Carpentry -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.interiorCarpentry')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Interior Carpentry"
          >
            <b-form-group label-for="Interior Carpentry">
              <v-select
                v-model="propertyData.interiorCarpentry"
                :options="
                  INTERIOR_CARPERNTRY.map((interiorCarpentry) => ({
                    label: $t('enums.interiorCarpentry.' + interiorCarpentry),
                    value: interiorCarpentry,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t(
                    'propertiesModel.propertyDetails.enumerators.interiorCarpentry',
                  )
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Carpentry Outside -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.carpentryOutside')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Interior Carpentry"
          >
            <b-form-group label-for="Interior Carpentry">
              <v-select
                v-model="propertyData.carpentryOutside"
                :options="
                  OUTSIDE_CARPENTRY.map((carpentryOutside) => ({
                    label: $t('enums.carpentryOutside.' + carpentryOutside),
                    value: carpentryOutside,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t(
                    'propertiesModel.propertyDetails.enumerators.carpentryOutside',
                  )
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Parking Numbered -->
        <div class="media d-flex align-items-center mr-auto">
          <!-- Checkbox: Parking Numbered -->
          <b-form-checkbox
            v-model="propertyData.isParkingNumbered"
            class="mb-1"
          />
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.booleans.parkingNumber')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Parking Number"
          >
            <b-form-group label-for="Parking Number">
              <b-form-input
                :disabled="!propertyData.isParkingNumbered"
                v-model="propertyData.parkingNumbered"
                class="form-control"
                style="width: 15vw"
                placeholder="1"
                :state="getValidationState(validationContext)"
                type="number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>

      <b-col cols="12" lg="4" md="4" sm="6">
        <!-- Field: Hot Water -->
        <div class="media d-flex align-items-center">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.hotWatter')
          }}</span>
          <validation-provider #default="validationContext" name="Hot Water">
            <b-form-group label-for="Hot Water">
              <v-select
                v-model="propertyData.hotWater"
                :options="
                  HOT_WATER.map((hotWater) => ({
                    label: $t('enums.hotWater.' + hotWater),
                    value: hotWater,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t('propertiesModel.propertyDetails.enumerators.hotWatter')
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Type of Floor -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.typeOfFloor')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Type of Floor"
          >
            <b-form-group label-for="Type of Floor">
              <v-select
                v-model="propertyData.typeOfFloor"
                :options="
                  TYPE_OF_FLOOR.map((typeOfFloor) => ({
                    label: $t('enums.typeOfFloor.' + typeOfFloor),
                    value: typeOfFloor,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t('propertiesModel.propertyDetails.enumerators.typeOfFloor')
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: View Type -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.viewType')
          }}</span>
          <validation-provider #default="validationContext" name="View Type">
            <b-form-group label-for="View Type">
              <v-select
                v-model="propertyData.viewType"
                :options="
                  VIEW_TYPE.map((viewType) => ({
                    label: $t('enums.viewType.' + viewType),
                    value: viewType,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t('propertiesModel.propertyDetails.enumerators.viewType')
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Garage -->
        <div class="media d-flex align-items-center mr-auto">
          <b-form-checkbox v-model="propertyData.hasGarage" class="mb-1" />
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.booleans.garageNumber')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Garage Number"
          >
            <b-form-group label-for="Garage Number">
              <b-form-input
                :disabled="!propertyData.hasGarage"
                v-model="propertyData.garageNumber"
                class="form-control"
                style="width: 15vw"
                placeholder="1"
                :state="getValidationState(validationContext)"
                type="number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>

      <b-col cols="12" lg="4" md="4" sm="6">
        <!-- Field: Energy Rating -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.energyRating')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Energy Rating"
          >
            <b-form-group label-for="Energy Rating">
              <v-select
                v-model="propertyData.energyRating"
                :options="
                  ENERGY_RATING.map((energyRating) => ({
                    label: $t('enums.energyRating.' + energyRating),
                    value: energyRating,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t('propertiesModel.propertyDetails.enumerators.energyRating')
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Energy Performance -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.energyPerformance')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Energy Performance"
          >
            <b-form-group label-for="Energy Performance">
              <b-form-input
                v-model="propertyData.energyPerformance"
                class="form-control"
                style="width: 15vw"
                placeholder="1"
                :state="getValidationState(validationContext)"
                type="number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Emissions Rating -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.emissionsRating')
          }}</span>
          <validation-provider
            #default="validationContext"
            name="Energy Rating"
          >
            <b-form-group label-for="Energy Rating">
              <v-select
                v-model="propertyData.emissionsRating"
                :options="
                  EMISSIONS_RATING.map((emissionsRating) => ({
                    label: $t('enums.emissionsRating.' + emissionsRating),
                    value: emissionsRating,
                  }))
                "
                :clearable="false"
                :state="getValidationState(validationContext)"
                :placeholder="
                  $t(
                    'propertiesModel.propertyDetails.enumerators.emissionsRating',
                  )
                "
                style="width: 15vw"
                item-value="value"
                item-text="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- Field: Emissions -->
        <div class="media d-flex align-items-center mr-auto">
          <span class="font-weight-bold mr-auto mb-1">{{
            $t('propertiesModel.propertyDetails.enumerators.emissions')
          }}</span>
          <validation-provider #default="validationContext" name="Emission">
            <b-form-group label-for="Emissions">
              <b-form-input
                v-model="propertyData.emissions"
                class="form-control"
                style="width: 15vw"
                placeholder="1"
                :state="getValidationState(validationContext)"
                type="number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        v-if="propertyData.hasTerrace"
      >
        <div class="d-flex pt-2 pb-2">
          <feather-icon icon="ListIcon" size="19" />
          <h4 class="ml-1">
            {{ $t('propertiesModel.propertyDetails.terraces.title') }}
          </h4>
        </div>

        <b-row
          v-for="(terrace, index) in propertyData.propertyTerraces"
          :key="index"
        >
          <!-- Field: Name -->
          <b-col cols="12" lg="3" md="4" sm="5">
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="terrace.name"
                  class="form-control"
                  :placeholder="
                    $t('propertiesModel.propertyDetails.terraces.name')
                  "
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Square meters -->
          <b-col cols="12" lg="3" md="4" sm="5">
            <validation-provider
              #default="validationContext"
              name="squareMeters"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="terrace.squareMeters"
                  class="form-control"
                  :placeholder="
                    $t('propertiesModel.propertyDetails.terraces.squareMeters')
                  "
                  :state="getValidationState(validationContext)"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col class="ma-0 pa-0">
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="$emit('removeTerrace', index)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>

        <b-button
          size="sm"
          variant="outline-primary"
          @click="$emit('addNewTerrace')"
          >{{ $t('propertiesModel.propertyDetails.terraces.button') }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import PropertyModel from '@/models/property.model'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  props: {
    data: {
      type: PropertyModel,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  watch: {
    propertyData: {
      deep: true,
      handler(value) {
        this.$emit('updateData', value)

        const valid =
          value.qtyBedrooms &&
          value.qtyBathrooms &&
          (!value.hasTerrace ||
            value.propertyTerraces.every((t) => t.name && t.squareMeters))

        console.log(valid)

        this.$emit('onValid', valid)
      },
    },
  },
  computed: {
    propertyData() {
      return this.data
    },
    KITCHEN_TYPES() {
      return this.$store.state.system.config.kitchenTypeOptions
    },
    KITCHEN_EQUIPPED() {
      return this.$store.state.system.config.equippedKitchenOptions
    },
    INTERIOR_CARPERNTRY() {
      return this.$store.state.system.config.interiorCarpentryOptions
    },
    OUTSIDE_CARPENTRY() {
      return this.$store.state.system.config.carpentryOutsideOptions
    },
    TYPE_OF_FLOOR() {
      return this.$store.state.system.config.typeOfFloorOptions
    },
    VIEW_TYPE() {
      return this.$store.state.system.config.viewTypeOptions
    },
    ENERGY_RATING() {
      return this.$store.state.system.config.enegyRatingOptions
    },
    EMISSIONS_RATING() {
      return this.$store.state.system.config.emissionsRatingOptions
    },
    HOT_WATER() {
      return this.$store.state.system.config.hotWaterOptions
    },
    ORIENTATION() {
      return this.$store.state.system.config.orientationOptions
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
