<template>
  <div class="mx-2">
    <!-- Header: Owner Details -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="ml-1">{{ $t('propertiesModel.ownerDetails.title') }}</h4>
    </div>
    <b-row align-v="center" align-h="start" class="mt-1">
      <!-- Field: Name -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.name')"
            label-for="name"
          >
            <b-form-input
              v-model="propertyData.owner.name"
              class="form-control"
              placeholder="John"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Email -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="email"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.email')"
            label-for="email"
          >
            <b-form-input
              v-model="propertyData.owner.email"
              class="form-control"
              placeholder="john@email.com"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: DNI -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="dni">
          <b-form-group :label="$t('socialInformations.dni')" label-for="dni">
            <b-form-input
              v-model="propertyData.owner.dni"
              class="form-control"
              placeholder="123456"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Telephone -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="telephone">
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.telephone')"
            label-for="telephone"
          >
            <b-form-input
              v-model="propertyData.owner.telephone"
              class="form-control"
              placeholder="123456789"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Cellphone -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="cellphone"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.cellphone')"
            label-for="cellphone"
          >
            <b-form-input
              v-model="propertyData.owner.cellphone"
              class="form-control"
              placeholder="Cellphone"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Country -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="country"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.country')"
            label-for="country"
          >
            <b-form-input
              v-model="propertyData.owner.country"
              class="form-control"
              :placeholder="$t('propertiesModel.ownerDetails.country')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Zip Code -->
      <b-col cols="2">
        <validation-provider #default="validationContext" name="zipcode">
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.zipCode')"
            label-for="zipcode"
          >
            <b-form-input
              v-model="propertyData.owner.zipCode"
              class="form-control"
              :placeholder="$t('propertiesModel.ownerDetails.zipCode')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: City -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="cityowner"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.city')"
            label-for="cityowner"
          >
            <b-form-input
              v-model="propertyData.owner.city"
              class="form-control"
              :placeholder="$t('propertiesModel.ownerDetails.city')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Address -->
      <b-col cols="6">
        <validation-provider #default="validationContext" name="address">
          <b-form-group
            :label="$t('propertiesModel.ownerDetails.address')"
            label-for="address"
          >
            <b-form-input
              v-model="propertyData.owner.address"
              :placeholder="$t('propertiesModel.ownerDetails.address')"
              :state="getValidationState(validationContext)"
              class="form-control"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PropertyModel from '@/models/property.model'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  props: {
    data: {
      type: PropertyModel,
    },
  },
  watch: {
    propertyData: {
      deep: true,
      handler(value) {
        this.$emit('updateData', value)

        const valid =
          value.owner &&
          value.owner.name &&
          value.owner.email &&
          value.owner.cellphone &&
          value.owner.country &&
          value.owner.city

        this.$emit('onValid', valid)
      },
    },
  },
  computed: {
    propertyData() {
      return this.data
    },
    PROVINCES() {
      return this.$store.state.system.config.provinceOptions
    },
    CITIES() {
      return this.$store.state.system.config.cityOptions
    },
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
