<template>
  <div v-if="propertyData">
    <b-card>
      <b-button
        class="generatePdfBtn mb-1"
        variant="outline-primary"
        @click="generatePdf()"
        v-if="propertyData.id != null"
      >
        <feather-icon icon="FileIcon" class="mr-50" />
        {{ $t('propertiesModel.generatePdf') }}
      </b-button>
      <!-- Form -->
      <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit(propertyData))">
          <b-tabs pills>
            <!-- General Information Tab -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">
                  {{ $t('propertiesModel.general.title') }}
                </span>

                <div
                  class="error-indicator"
                  :class="{ visible: !generalValid }"
                ></div>
              </template>

              <general-info-component
                class="mt-4"
                :data.sync="propertyData"
                @updateData="updateData"
                @onValid="generalValid = $event"
              />
            </b-tab>

            <!-- Property Details Tab -->
            <b-tab>
              <template #title>
                <feather-icon icon="HomeIcon" size="16" class="mr-0 mr-sm-50" />

                <span class="d-none d-sm-inline">{{
                  $t('propertiesModel.propertyDetails.title')
                }}</span>

                <div
                  class="error-indicator"
                  :class="{ visible: !propertyValid }"
                ></div>
              </template>

              <property-details-component
                class="mt-4"
                :data.sync="propertyData"
                @updateData="updateData"
                @addNewTerrace="addNewTerrace"
                @removeTerrace="removeTerrace"
                @hasTerraceChanged="hasTerraceChanged"
                @onValid="propertyValid = $event"
              />
            </b-tab>

            <!-- Owner Details Tab -->
            <b-tab>
              <template #title>
                <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />

                <span class="d-none d-sm-inline">{{
                  $t('propertiesModel.ownerDetails.title')
                }}</span>

                <div
                  class="error-indicator"
                  :class="{ visible: !ownerValid }"
                ></div>
              </template>

              <owner-details-component
                class="mt-4"
                :data.sync="propertyData"
                @updateData="updateData"
                @onValid="ownerValid = $event"
              />
            </b-tab>

            <!-- Gallery Tab -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="ImageIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />

                <span class="d-none d-sm-inline">{{
                  $t('propertiesModel.gallery.title')
                }}</span>
              </template>

              <gallery-component
                class="mt-4"
                :isNew="$route.params.id == 'new' ? true : false"
                :data.sync="propertyData"
                @updateData="updateData"
                @removeImgObjFromData="removeImgObjFromData"
                @removeImgFileFromData="removeImgFileFromData"
              />
            </b-tab>
          </b-tabs>
        </b-form>
      </validation-observer>

      <b-row class="mt-2 pr-1" align-h="end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: 'property-list' }"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="validate()"
        >
          <b-spinner v-if="loading" small :label="$t('commons.loading')" />
          <span v-else>{{ $t('buttons.save') }}</span>
        </b-button>
      </b-row>
    </b-card>
  </div>
  <SpinnerLoading v-else />
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BButton,
  BForm,
  BTab,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, localize } from 'vee-validate'
import router from '@/router'
import store from '@/store'
import propertyStoreModule from './propertyStoreModule'
import PropertyModel from '@/models/property.model'
import usePropertyList from './usePropertyList'
import GeneralInfoComponent from './components/generalnfoComponent.vue'
import PropertyDetailsComponent from './components/propertyDetailsComponent.vue'
import OwnerDetailsComponent from './components/ownerDetailsComponent.vue'
import GalleryComponent from './components/galleryComponent.vue'
import SpinnerLoading from '@/components/SpinnerLoading.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BButton,
    BForm,
    BTab,
    BSpinner,

    GeneralInfoComponent,
    SpinnerLoading,
    ValidationObserver,
    PropertyDetailsComponent,
    OwnerDetailsComponent,
    GalleryComponent,
  },
  data() {
    return {
      generalValid: false,
      propertyValid: false,
      ownerValid: false,
    }
  },
  methods: {
    async generatePdf() {
      const data = await this.$store.dispatch(
        'app-property/generatePdf',
        this.propertyData.id,
      )
      const url = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `${this.propertyData.ref}`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    },
    removeImgObjFromData(imgObj) {
      const index = this.propertyData.images.findIndex(
        (value) => value.id === imgObj.id,
      )
      this.propertyData.images.splice(index, 1)
    },
    removeImgFileFromData(imgFile) {
      const index = this.propertyData.images.findIndex(
        (value) => value === imgFile,
      )
      this.propertyData.images.splice(index, 1)
    },
    updateData(value) {
      this.propertyData = value
    },
    addNewTerrace() {
      this.propertyData.propertyTerraces.push({
        name: null,
        squareMeters: null,
      })
    },
    removeTerrace(index) {
      if (this.propertyData.propertyTerraces.length > 1) {
        this.propertyData.propertyTerraces.splice(index, 1)
      }
    },
    hasTerraceChanged(changed) {
      if (changed) {
        this.addNewTerrace()
      } else {
        this.propertyData.propertyTerraces = []
      }
    },
    async validate() {
      if (await this.$refs.refFormObserver.validate()) {
        this.propertyData.propertyActionId = 1
        this.propertyData.officeId = 1
        this.onSubmit(this.propertyData)
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.missing.error'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      localize(newVal)
      this.$refs.refFormObserver.validate()
    },
  },

  computed: {
    loading() {
      return this.$store.state['app-property'].loading
    },
  },

  setup() {
    const blankpropertyData = new PropertyModel({
      province: 'MALAGA',
      owner: {
        province: 'MALAGA',
      },
      propertyTerraces: [],
    })
    const propertyData = ref(null)

    const property_APP_STORE_MODULE_NAME = 'app-property'

    const resetpropertyData = () => {
      propertyData.value = JSON.parse(JSON.stringify(blankpropertyData))
    }

    const toast = useToast()

    // Register module
    if (!store.hasModule(property_APP_STORE_MODULE_NAME)) {
      store.registerModule(property_APP_STORE_MODULE_NAME, propertyStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(property_APP_STORE_MODULE_NAME))
        store.unregisterModule(property_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch(
          'app-property/fetchOneProperty',
          router.currentRoute.params.id,
        )
        .then((response) => {
          propertyData.value = response
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.fetch.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          if (error.response.status === 404) {
            propertyData.value = undefined
          }
        })
    } else {
      propertyData.value = blankpropertyData
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpropertyData)

    const { onSubmit } = usePropertyList()

    return {
      toast,
      propertyData,
      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.tabs /deep/ a[role='tab'] {
  position: relative;
}

.error-indicator {
  position: absolute;
  right: 0;
  top: 0;

  width: 0.7rem;
  height: 0.7rem;

  border-radius: 50%;
  background-color: indianred;
  box-shadow: 0 0 8px indianred;

  transform: translate(-50%, 50%) scale(0);

  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.error-indicator.visible {
  transform: translate(-50%, 50%) scale(1);
}

@media (min-width: 940px) {
  .generatePdfBtn {
    position: absolute;
    right: 0;
    margin-right: 40px;
  }
}
</style>
