<template>
  <div class="mx-2" v-if="isDataAvaiable">
    <!-- Header: General Info -->
    <div class="d-flex">
      <feather-icon icon="FileTextIcon" size="19" />
      <h4 class="ml-50">{{ $t('propertiesModel.general.title') }}</h4>
    </div>

    <b-row align-v="center" align-h="start" class="mt-1">
      <!-- Field: REF -->
      <b-col cols="2">
        <validation-provider
          #default="validationContext"
          name="ref"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.general.ref') + '*'"
            label-for="ref"
          >
            <b-form-input
              v-model="propertyData.ref"
              class="form-control"
              placeholder="001"
              :state="getValidationState(validationContext)"
              type="text"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Type -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="type"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.general.propertyType') + '*'"
            label-for="type"
          >
            <v-select
              v-model="propertyData.propertyTypeId"
              :options="
                (propertyTypes || []).map((e) => ({
                  ...e,
                  label: e.label[$i18n.locale],
                }))
              "
              :clearable="false"
              :state="getValidationState(validationContext)"
              :placeholder="$t('propertiesModel.general.propertyType')"
            >
              <span slot="no-options" @click="$refs.select.open = false">
                {{ $t('alert.select.empty') }}
              </span>
            </v-select>
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Price -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="price">
          <b-form-group
            :label="$t('propertiesModel.general.price') + ' (€)'"
            label-for="price"
          >
            <b-form-input
              v-model="propertyData.price"
              class="form-control"
              placeholder="150.000,52"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Action -->
      <!--<b-col cols="2">
        <validation-provider
          #default="validationContext"
          name="action"
          rules="required"
        >
          <b-form-group label="Property Action*" label-for="action">
            <v-select
              v-model="propertyData.propertyActionId"
              :options="propertyActions"
              :clearable="false"
              :state="getValidationState(validationContext)"
              placeholder="Property Action"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>-->
      <!-- Field: Comission -->
      <b-col cols="2">
        <validation-provider #default="validationContext" name="Comission">
          <b-form-group
            :label="$t('propertiesModel.general.comission') + ' (€)'"
            label-for="Comission"
          >
            <b-form-input
              v-model="propertyData.comission"
              class="form-control"
              placeholder="300,00"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Visit Hours -->
      <b-col cols="2">
        <validation-provider #default="validationContext" name="Visit Hours">
          <b-form-group
            :label="$t('propertiesModel.general.visitHours')"
            label-for="Visit Hours"
          >
            <b-form-input
              v-model="propertyData.visitHours"
              class="form-control"
              placeholder="17:00 - 20:00"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Sales Person -->
      <b-col cols="2">
        <validation-provider
          #default="validationContext"
          name="Sales Person"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.general.subscriber') + '*'"
            label-for="Sales Person"
          >
            <v-select
              v-model="propertyData.userId"
              :options="subscriber"
              :clearable="false"
              :state="getValidationState(validationContext)"
              :placeholder="$t('propertiesModel.general.subscriber')"
            >
              <span slot="no-options" @click="$refs.select.open = false">
                {{ $t('alert.select.empty') }}
              </span>
            </v-select>
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Mortgages Left -->
      <!--<b-col cols="4">
        <validation-provider #default="validationContext" name="Mortgages Left">
          <b-form-group :label="$t('propertiesModel.general.mortgagesLeft') + ' (€)'" label-for="Mortgages Left">
            <b-form-input
              v-model="propertyData.mortgagesLeft"
              class="form-control"
              placeholder="0,00"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>-->

      <!-- Field: Property Register -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="Property Register"
        >
          <b-form-group
            :label="$t('propertiesModel.general.propertyRegister')"
            label-for="Property Register"
          >
            <b-form-input
              v-model="propertyData.propertyRegister"
              :placeholder="$t('placeholder.propertyRegister')"
              :state="getValidationState(validationContext)"
              class="form-control"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Registry -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Registry">
          <b-form-group
            :label="$t('propertiesModel.general.registerNumber')"
            label-for="Registry"
          >
            <b-form-input
              v-model="propertyData.registry"
              :placeholder="$t('propertiesModel.general.registerNumber')"
              :state="getValidationState(validationContext)"
              type="number"
              class="form-control"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Buy Expenses -->
      <!--<b-col cols="2">
        <validation-provider #default="validationContext" name="Buy Expenses">
          <b-form-group :label="$t('propertiesModel.general.buyExpenses') + ' (€)'" label-for="Buy Expenses">
            <b-form-input
              v-model="propertyData.buyExpenses"
              class="form-control"
              placeholder="100,00"
              :state="getValidationState(validationContext)"
              type="number"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>-->

      <!-- Field: Expiry Date -->
      <!--<b-col cols="4">
        <validation-provider #default="validationContext" name="Expiry Date">
          <b-form-group :label="$t('propertiesModel.general.expiryDate')" label-for="Expiry Date">
            <b-form-datepicker
              v-model="propertyData.expiryDate"
              class="form-control"
              :placeholder="$t('placeholder.date')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>-->
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Office -->
      <!--<b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="Office"
          rules="required"
        >
          <b-form-group label="Office*" label-for="Office">
            <v-select
              v-model="propertyData.officeId"
              :options="offices"
              :clearable="false"
              :state="getValidationState(validationContext)"
              placeholder="Office"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>-->
    </b-row>

    <!-- Header: Address Info -->
    <div class="d-flex mt-1">
      <feather-icon icon="MapIcon" size="19" />
      <h4 class="ml-50">{{ $t('propertiesModel.address.title') }}</h4>
    </div>

    <b-row align-v="center" align-h="start">
      <!-- Field: Zip Code -->
      <b-col cols="2">
        <validation-provider
          #default="validationContext"
          name="zipcode"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.address.zipCode') + '*'"
            label-for="zipcode"
          >
            <b-form-input
              v-model="propertyData.zipCode"
              class="form-control"
              placeholder="52000"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Province -->
      <b-col cols="3">
        <validation-provider
          #default="validationContext"
          name="province"
          rules="required"
        >
          <b-form-group
            :label="$t('addressInformations.province') + '*'"
            label-for="province"
          >
            <v-select
              v-model="propertyData.province"
              :options="PROVINCES"
              :clearable="false"
              :state="getValidationState(validationContext)"
              placeholder="Province"
              :disabled="true"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: City -->
      <b-col cols="4">
        <validation-provider
          #default="validationContext"
          name="city"
          rules="required"
        >
          <b-form-group
            :label="$t('addressInformations.city') + '*'"
            label-for="city"
          >
            <v-select
              v-model="propertyData.city"
              :options="CITIES"
              :clearable="false"
              :state="getValidationState(validationContext)"
              :placeholder="$t('addressInformations.city')"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Address Track Type -->
      <b-col cols="3">
        <validation-provider #default="validationContext" name="tracktype">
          <b-form-group
            :label="$t('propertiesModel.address.trackType')"
            label-for="tracktype"
          >
            <v-select
              v-model="propertyData.addressTrackType"
              :options="
                TRACKTYPES.map((tracktype) => ({
                  label: $t('enums.trackTypes.' + tracktype),
                  value: tracktype,
                }))
              "
              :clearable="false"
              :state="getValidationState(validationContext)"
              :placeholder="$t('propertiesModel.address.trackType')"
              item-value="value"
              item-text="label"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Address Name -->
      <b-col cols="6">
        <validation-provider
          #default="validationContext"
          name="address"
          rules="required"
        >
          <b-form-group
            :label="$t('propertiesModel.address.address') + '*'"
            label-for="address"
          >
            <b-form-input
              v-model="propertyData.addressTrackName"
              :placeholder="$t('propertiesModel.address.address')"
              :state="getValidationState(validationContext)"
              class="form-control"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Zone -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="zone">
          <b-form-group
            :label="$t('propertiesModel.address.zone')"
            label-for="zone"
          >
            <v-select
              v-model="propertyData.zoneId"
              :options="zones"
              :clearable="false"
              :state="getValidationState(validationContext)"
              :placeholder="$t('propertiesModel.address.zone')"
            >
              <span slot="no-options" @click="$refs.select.open = false">
                {{ $t('alert.select.empty') }}
              </span>
            </v-select>
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Terrain Number -->
      <b-col cols="2">
        <validation-provider #default="validationContext" name="Terrain Number">
          <b-form-group
            :label="$t('propertiesModel.address.terrainNumber')"
            label-for="Terrain Number"
          >
            <b-form-input
              v-model="propertyData.terrainNumber"
              :placeholder="$t('propertiesModel.address.terrainNumber')"
              :state="getValidationState(validationContext)"
              type="number"
              class="form-control"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="start">
      <!-- Field: Street Number -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Street Number">
          <b-form-group
            :label="$t('propertiesModel.address.streetNumber')"
            label-for="Street Number"
          >
            <b-form-input
              v-model="propertyData.streetNumber"
              class="form-control"
              :placeholder="$t('propertiesModel.address.streetNumber')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Block -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Block">
          <b-form-group
            :label="$t('propertiesModel.address.block')"
            label-for="Block"
          >
            <b-form-input
              v-model="propertyData.block"
              class="form-control"
              :placeholder="$t('propertiesModel.address.block')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Floor -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Floor">
          <b-form-group
            :label="$t('propertiesModel.address.floor')"
            label-for="Floor"
          >
            <b-form-input
              v-model="propertyData.floor"
              class="form-control"
              :placeholder="$t('propertiesModel.address.floor')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Header: Notes -->
    <div class="d-flex mt-1">
      <feather-icon icon="FileIcon" size="19" />
      <h4 class="ml-50">{{ $t('propertiesModel.notes.title') }}</h4>
    </div>

    <b-row align-v="center" align-h="start">
      <!-- Field: Description EN -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Title EN">
          <b-form-group
            :label="$t('propertiesModel.notes.titleEnglish')"
            label-for="Title EN"
          >
            <b-form-input
              v-model="propertyData.titleWebEn"
              :placeholder="$t('propertiesModel.notes.titleEnglish')"
              :state="getValidationState(validationContext)"
              class="form-control"
              rows="3"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name="Description EN">
          <b-form-group
            :label="$t('propertiesModel.notes.descriptionEnglish')"
            label-for="Description EN"
          >
            <b-form-textarea
              v-model="propertyData.descriptionEn"
              :placeholder="$t('propertiesModel.notes.descriptionEnglish')"
              :state="getValidationState(validationContext)"
              class="form-control"
              rows="3"
              max-rows="8"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Field: Description ES -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Título ES">
          <b-form-group
            :label="$t('propertiesModel.notes.titleSpanish')"
            label-for="Título ES"
          >
            <b-form-input
              v-model="propertyData.titleWebEs"
              :placeholder="$t('propertiesModel.notes.titleSpanish')"
              :state="getValidationState(validationContext)"
              class="form-control"
              rows="3"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name="Description ES">
          <b-form-group
            :label="$t('propertiesModel.notes.descriptionSpanish')"
            label-for="Description ES"
          >
            <b-form-textarea
              v-model="propertyData.descriptionEs"
              :placeholder="$t('propertiesModel.notes.descriptionSpanish')"
              :state="getValidationState(validationContext)"
              class="form-control"
              rows="3"
              max-rows="8"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Field: Description SW -->
      <b-col cols="4">
        <validation-provider #default="validationContext" name="Titel SW">
          <b-form-group
            :label="$t('propertiesModel.notes.titleSwedish')"
            label-for="Titel SW"
          >
            <b-form-input
              v-model="propertyData.titleWebSw"
              :placeholder="$t('propertiesModel.notes.titleSwedish')"
              :state="getValidationState(validationContext)"
              class="form-control"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name="Description SW">
          <b-form-group
            :label="$t('propertiesModel.notes.descriptionSwedish')"
            label-for="Description SW"
          >
            <b-form-textarea
              v-model="propertyData.descriptionSw"
              :placeholder="$t('propertiesModel.notes.descriptionSwedish')"
              :state="getValidationState(validationContext)"
              class="form-control"
              rows="3"
              max-rows="8"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row align-v="center">
      <b-col cols="12" lg="6" md="6">
        <!-- Field: Internal Observations -->
        <validation-provider
          #default="validationContext"
          name="Internal Observations"
        >
          <b-form-group
            :label="$t('propertiesModel.notes.internalObservations')"
            label-for="Internal Observations"
          >
            <b-form-textarea
              v-model="propertyData.internalObservations"
              :placeholder="$t('propertiesModel.notes.internalObservations')"
              :state="getValidationState(validationContext)"
              class="form-control"
              rows="3"
              max-rows="8"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col class="mt-1">
        <!-- Checkbox: Favorite -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isFavorite" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.favorite')
          }}</span>
        </div>

        <!-- Checkbox: Reserved -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isReserved" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.reserved')
          }}</span>
        </div>

        <!-- Checkbox: Sold -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isSold" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.sold')
          }}</span>
        </div>

        <!-- Checkbox: Highlighted -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isHighlighted" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.highlighted')
          }}</span>
        </div>
      </b-col>
      <b-col class="mt-1">
        <!-- Checkbox: Signed -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isSigned" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.signed')
          }}</span>
        </div>

        <!-- Checkbox: Available -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isAvailable" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.available')
          }}</span>
        </div>

        <!-- Checkbox: Has Available Keys -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.hasAvailableKeys" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.availableKeys')
          }}</span>
        </div>

        <!-- Checkbox: Exclusive -->
        <div class="media d-flex align-items-center mb-1">
          <b-form-checkbox v-model="propertyData.isExclusive" />
          <span class="font-weight-bold">{{
            $t('propertyStatus.status.exclusive')
          }}</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import PropertyModel from '@/models/property.model'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import usePropertyTypesList from '@/views/propertyTypes/usePropertyTypesList'
import propertyTypesStoreModule from '@/views/propertyTypes/propertyTypesStoreModule'
import usePropertyActionsList from '@/views/propertyActions/usePropertyActionsList'
import propertyActionsStoreModule from '@/views/propertyActions/propertyActionsStoreModule'
import useZonesList from '@/views/zone/useZonesList'
import zonesStoreModule from '@/views/zone/zonesStoreModule'
import useUsersList from '@/views/user/useUsersList'
import usersStoreModule from '@/views/user/usersStoreModule'
import router from '@/router'
import useOfficeList from '@/views/office/useOfficeList'
import officeStoreModule from '@/views/office/officeStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  props: {
    data: {
      type: PropertyModel,
    },
  },
  watch: {
    propertyData: {
      deep: true,
      handler(value) {
        this.$emit('updateData', value)

        const valid =
          value.ref &&
          value.propertyTypeId &&
          value.userId &&
          value.zipCode &&
          value.province &&
          value.city &&
          value.addressTrackName

        this.$emit('onValid', valid)
      },
    },
  },
  computed: {
    isDataAvaiable() {
      if (
        this.propertyTypes != null &&
        this.propertyActions != null &&
        this.zones != null &&
        this.subscriber != null &&
        this.offices != null &&
        this.CITIES != null &&
        this.PROVINCES != null &&
        this.TRACKTYPES != null
      ) {
        return true
      }
      return false
    },
    PROVINCES() {
      return this.$store.state.system.config.provinceOptions
    },
    CITIES() {
      return this.$store.state.system.config.cityOptions
    },
    TRACKTYPES() {
      return this.$store.state.system.config.addressTrackTypeOptions
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props) {
    const propertyTypes = ref(null)
    const propertyActions = ref(null)
    const zones = ref(null)
    const subscriber = ref([])
    const offices = ref(null)
    const blankpropertyData = new PropertyModel({})
    const propertyData = ref(new PropertyModel(props.data))
    const PROPERTY_TYPES_APP_STORE_MODULE_NAME = 'app-property-types'
    const PROPERTY_ACTIONS_APP_STORE_MODULE_NAME = 'app-property-actions'
    const ZONES_APP_STORE_MODULE_NAME = 'app-zones'
    const USERS_APP_STORE_MODULE_NAME = 'app-users'
    const office_APP_STORE_MODULE_NAME = 'app-office'

    const resetpropertyData = () => {
      propertyData.value = JSON.parse(JSON.stringify(blankpropertyData))
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpropertyData)

    // Register module
    if (!store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_TYPES_APP_STORE_MODULE_NAME,
        propertyTypesStoreModule,
      )
    }
    if (!store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_ACTIONS_APP_STORE_MODULE_NAME,
        propertyActionsStoreModule,
      )
    }
    if (!store.hasModule(ZONES_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONES_APP_STORE_MODULE_NAME, zonesStoreModule)
    }
    if (!store.hasModule(USERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(USERS_APP_STORE_MODULE_NAME, usersStoreModule)
    }
    if (!store.hasModule(office_APP_STORE_MODULE_NAME)) {
      store.registerModule(office_APP_STORE_MODULE_NAME, officeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)
      if (store.hasModule(ZONES_APP_STORE_MODULE_NAME))
        store.unregisterModule(ZONES_APP_STORE_MODULE_NAME)
      if (store.hasModule(USERS_APP_STORE_MODULE_NAME))
        store.unregisterModule(USERS_APP_STORE_MODULE_NAME)
      if (store.hasModule(office_APP_STORE_MODULE_NAME))
        store.unregisterModule(office_APP_STORE_MODULE_NAME)
    })

    const { listPropertyTypes } = usePropertyTypesList()

    listPropertyTypes().then((response) => {
      propertyTypes.value = response

      if (router.currentRoute.params.id !== 'new') {
        const index = propertyTypes.value.findIndex(
          (item) => item.key === propertyData.value.propertyTypeId,
        )

        if (index !== -1) {
          propertyData.value.propertyTypeId = propertyTypes.value[index]
        }
      }
    })

    const { listPropertyActions } = usePropertyActionsList()

    listPropertyActions().then((response) => {
      propertyActions.value = response

      if (router.currentRoute.params.id !== 'new') {
        const index = propertyActions.value.findIndex(
          (item) => item.key === propertyData.value.propertyActionId,
        )

        if (index !== -1) {
          propertyData.value.propertyActionId = propertyActions.value[index]
        }
      }
    })

    const { listZones } = useZonesList()

    listZones().then((response) => {
      zones.value = response

      if (router.currentRoute.params.id !== 'new') {
        const index = zones.value.findIndex(
          (item) => item.key === propertyData.value.zoneId,
        )

        if (index !== -1) {
          propertyData.value.zoneId = zones.value[index]
        }
      }
    })

    const { listOffices } = useOfficeList()

    listOffices().then((response) => {
      offices.value = response

      if (router.currentRoute.params.id !== 'new') {
        const index = offices.value.findIndex(
          (item) => item.key === propertyData.value.officeId,
        )

        if (index !== -1) {
          propertyData.value.officeId = offices.value[index]
        }
      }
    })

    const { listUsersByRole } = useUsersList()

    listUsersByRole('salesperson', true).then((response) => {
      subscriber.value.push(...response)

      if (router.currentRoute.params.id !== 'new') {
        const index = subscriber.value.findIndex(
          (item) => item.key === propertyData.value.userId,
        )

        if (index !== -1) {
          propertyData.value.userId = subscriber.value[index]
        }
      }
    })

    listUsersByRole('superadmin', true).then((response) => {
      subscriber.value.push(...response)

      if (router.currentRoute.params.id !== 'new') {
        const index = subscriber.value.findIndex(
          (item) => item.key === propertyData.value.userId,
        )

        if (index !== -1) {
          propertyData.value.userId = subscriber.value[index]
        }
      }
    })

    return {
      propertyData,
      refFormObserver,
      getValidationState,
      resetForm,

      propertyTypes,
      propertyActions,
      zones,
      subscriber,
      offices,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
